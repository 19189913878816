import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import SupplierReducer from './reducers/supplierSlice';
import counterSlice from './reducers/stylesSlice';
import stylesSlice from './reducers/stylesSlice';
import { shoeApi } from './services';
import projectBuildProjectSlice from './reducers/projectBuildProjectSlice';

const store = configureStore({
  reducer: {
    supplier: SupplierReducer,
    counter: counterSlice,
    styles: stylesSlice,
    projectBuildProjectSlice: projectBuildProjectSlice,
    [shoeApi.reducerPath]: shoeApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(shoeApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

//Writing these here to prevent defining the types in every file
export const useAppDispatch = () => useDispatch<AppDispatch>(); //This is used to perform action
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
// Used to get the data from the store in the component

export default store;

import {
  BulkPoType,
  Buyer,
  InputMaybe,
  MerchLeadType,
  ProjectBuildProjectType,
  ProjectBuildProjectLineInput,
  ProjectBuildTypes,
  Supplier,
} from '@/gql/graphql';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProjectBuildPoState {
  formValues: ProjectBuildProjectType;
  suppliers: Supplier[];
  buyers: Buyer[];
  projectTypes: ProjectBuildTypes[];
  merchLeads: MerchLeadType[];
  poLineData: ProjectBuildProjectLineInput[];
  isProjectCreated: boolean;
}

const initialState: ProjectBuildPoState = {
  formValues: {
    project_id: '',
    supplier_id: '',
    supplier_name: '',
    buyer_id: 0,
    buyer_name: '',
    project_type: '',
    type_name: '',
    lead_name: '',
    lead_id: 0,
    project_name: '',
    project_note: '',
  },
  suppliers: [],
  buyers: [],
  projectTypes: [],
  merchLeads: [],
  poLineData: [],
  isProjectCreated: false,
};

export const projectBuildProjectSlice = createSlice({
  name: 'projectBuildPo',
  initialState,
  reducers: {
    setNewProjectData: (state, action: PayloadAction<ProjectBuildProjectType>) => {
      state.formValues = action.payload;
    },

    updateFormField: (state, action: PayloadAction<Partial<ProjectBuildProjectType>>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
    clearFormValues: (state) => {
      state.formValues = initialState.formValues;
    },
    setSuppliers: (state, action: PayloadAction<Supplier[]>) => {
      state.suppliers = action.payload;
    },
    setBuyers: (state, action: PayloadAction<Buyer[]>) => {
      state.buyers = action.payload;
    },
    setProjectTypes: (state, action: PayloadAction<ProjectBuildTypes[]>) => {
      state.projectTypes = action.payload;
    },
    setMerchLeads: (state, action: PayloadAction<MerchLeadType[]>) => {
      state.merchLeads = action.payload;
    },
    setPoLineData: (state, action: PayloadAction<ProjectBuildProjectLineInput[]>) => {
      state.poLineData = action.payload;
    },
    setProjectCreated: (state, action: PayloadAction<boolean>) => {
      state.isProjectCreated = action.payload;
    },
  },
});

export const {
  setNewProjectData,
  updateFormField,
  clearFormValues,
  setSuppliers,
  setBuyers,
  setProjectTypes,
  setMerchLeads,
  setPoLineData,
  setProjectCreated,
} = projectBuildProjectSlice.actions;

export default projectBuildProjectSlice.reducer;
